<template>
  <div>
    <div class="min-vh-100">
      <CRow class="w-100 no-gutters px-3 px-sm-0">
        <CCol cols="6">
          <h1 class="mr-sm-4 header">SUGGEST</h1>
        </CCol>
        <CCol cols="6" class="text-right">
          <b-dropdown id="dropdown-1" class="mr-2 btn-mains small-dropdown medium-dd" right no-flip>
            <template v-slot:button-content>
              <font-awesome-icon icon="ellipsis-v" title="filter-btn" class="text-white d-sm-none" />
              <span class="d-none d-sm-inline">ACTION</span>
            </template>
            <b-dropdown-item @click="exportData(1)">Export Excel</b-dropdown-item>
            <b-dropdown-item @click="exportData(2)">Export CSV</b-dropdown-item>
          </b-dropdown>

          <b-button v-b-toggle.sidebar-1 class="btn-filter mr-0 mr-sm-1">
            <font-awesome-icon icon="filter" title="filter-btn" class="text-white mr-0 mr-sm-1" />
            <span class="d-none d-sm-inline">FILTER</span>
          </b-button>
        </CCol>
      </CRow>
      <b-sidebar
        id="sidebar-1"
        title="FILTER"
        backdrop
        shadow
        backdrop-variant="dark"
        right
        ref="filterSidebar"
      >
        <div class="px-3 py-2">
          <div class="text-right">
            <button type="button" class="btn btn-link px-0" @click="onClearFilter()">Clear</button>
          </div>
          <div class="row mt-2">
            <div class="col-6">
              <p class="font-weight-bold my-2">Start Date</p>
              <datetime
                placeholder="Please select date"
                class="date-filter"
                v-model="filter.startDate"
                format="dd MMM yyyy"
              ></datetime>
            </div>
            <div class="col-6">
              <p class="font-weight-bold my-2">End Date</p>
              <datetime
                placeholder="Please select date"
                class="date-filter"
                v-model="filter.endDate"
                format="dd MMM yyyy"
              ></datetime>
            </div>
          </div>

          <div>
            <p class="font-weight-bold my-2">Status</p>
          </div>

          <!-- <div class="form-check mb-2">
            <input
              class="form-check-input"
              type="checkbox"
              value
              id="all"
              :checked="checkAll"
              @click="checkAllSelect()"
              v-model="selectAllCb"
            />
            <label class="form-check-label" for="all">All</label>
          </div>-->
          <div class="row">
            <div class="col-6" v-for="status in complaintStatusList" :key="status.id">
              <div class="form-check mb-2">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :value="status.id"
                  v-model="filter.complaintStatus"
                  checked="checked"
                  :id="'status-'+status.id"
                />
                <label class="form-check-label" :for="'status-'+status.id">{{status.name}}</label>
              </div>
            </div>
          </div>

          <div class="text-center mt-3">
            <button type="button" class="btn btn-main button" @click="getDataByStatus">Submit</button>
          </div>
        </div>
      </b-sidebar>
      <div class="bg-white-border px-4 px-sm-5 mt-3 py-4">
        <b-row class="no-gutters justify-content-between">
          <b-col md="5" class="px-0 py-3 pt-lg-2">
            <b-input-group class="panel-input-serach">
              <b-form-input
                class="input-serach"
                placeholder="Customer Name, Line Name, Mobile No., Details"
                v-model="filter.search"
                @keyup="handleSearch"
              ></b-form-input>
              <b-input-group-prepend @click="btnSearch">
                <span class="icon-input m-auto pr-2">
                  <font-awesome-icon icon="search" title="View" />
                </span>
              </b-input-group-prepend>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-table
              responsive
              class="text-center"
              striped
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
            >
              <template v-slot:cell(customerName)="data">
                <div v-if="data.item.customerName == null">-</div>
                <div v-else>{{data.item.customerName}}</div>
              </template>
              <template v-slot:cell(ipAddress)="data">
                <div v-if="data.item.ipAddress == ''">-</div>
                <div v-else>{{data.item.ipAddress}}</div>
              </template>
              <template v-slot:cell(callBack)="data">
                <div v-if="data.item.callBack == true">ใช่</div>
                <div v-else>ไม่ใช่</div>
              </template>
              <template v-slot:cell(attachFile)="data">
               <div v-if="data.item.attachFile == true">ใช่
                  <span v-if="data.item.removeFiles > 0"> (ลบแล้ว {{data.item.removeFiles}} ไฟล์)</span>
                </div>
                <div v-else>ไม่ใช่</div>
              </template>
              <template v-slot:cell(detail)="data">
                <div v-if="data.item.detail == null || data.item.detail == ''">-</div>
                <div v-else>
                  <p class="two-lines m-0">{{data.item.detail}}</p>
                </div>
              </template>
              <template v-slot:cell(createdTime)="data">
                <div v-if="data.item.createdTime == null">-</div>
                <div v-else>
                  <span>
                    {{
                    data.item.createdTime | moment($formatDateTime)
                    }}
                  </span>
                </div>
              </template>
              <template v-slot:cell(updatedTime)="data">
                <div v-if="data.item.updatedTime == null">-</div>
                <div v-else>
                  <span>
                    {{
                    data.item.updatedTime | moment($formatDate)
                    }}
                  </span>
                </div>
              </template>
              <template v-slot:cell(id)="data">
                <b-button variant="link" class="px-1 py-0">
                  <router-link :to="'/feedback/details/'+ data.item.id">
                    <font-awesome-icon icon="pencil-alt" class="text-warning" title="View" />
                  </router-link>
                </b-button>
              </template>
              <template v-slot:cell(lineName)="data">
                <div v-if="data.item.lineName == null || data.item.lineName == ''">-</div>
                <div v-else>{{data.item.lineName}}</div>
              </template>
               <template v-slot:cell(camplaintStatusName)="data">
                <span class="doc-status" :style="{ color: data.item.statusColor }">{{ data.item.camplaintStatusName }}</span>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="form-inline justify-content-center justify-content-sm-between">
            <div class="d-sm-flex mt-2">
              <b-pagination
                v-model="filter.page"
                :total-rows="rows"
                :per-page="filter.pageSize"
                class="m-md-0"
                @change="pagination"
                align="center"
              ></b-pagination>

              <div class="ml-2">
                <p class="total-record-paging text-nowrap text-center">{{totalRowMessage}}</p>
              </div>
            </div>

            <b-form-select
              v-model="filter.pageSize"
              @change="hanndleChangePerpage"
              :options="pageOptions"
            ></b-form-select>
          </b-col>
        </b-row>
      </div>
    </div>

    <b-modal ref="loadingModal" hide-header hide-footer centered>
      <div class="text-center">
        <img src="@/assets/images/icons/loading.svg" alt="loading" class="mb-3 w-25" />
        <h1 class="font-weight-bold text-modal">In progress. Exporting Data...</h1>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";

export default {
  name: "user",
  data() {
    return {
      statusList: [],
      modalMessage: "",
      requestDeleteUser: {
        userId: null,
      },
      fields: [
        {
          key: "createdTime",
          label: "Transaction Date",
          class: "w-100px text-nowrap",
        },
        {
          key: "customerName",
          label: "Customer Name",
          class: "w-100px text-nowrap",
        },
        {
          key: "lineName",
          label: "Line Name",
          class: "w-50px text-nowrap",
        },
        {
          key: "mobileNo",
          label: "Mobile No.",
          class: "w-50px text-nowrap",
        },
        {
          key: "detail",
          label: "Details",
          class: "w-100px text-nowrap",
        },
        {
          key: "callBack",
          label: "Call",
          class: "w-50px text-nowrap",
        },
        {
          key: "attachFile",
          label: "Attach File",
          class: "w-50px text-nowrap",
        },
        {
          key: "updatedTime",
          label: "Last Update",
          class: "w-50px text-nowrap",
        },
        {
          key: "camplaintStatusName",
          label: "Status",
          class: "w-50px text-nowrap",
        },
        {
          key: "ipAddress",
          label: "IP Address",
          class: "w-50px text-nowrap",
        },
        {
          key: "id",
          label: "Action",
        },
      ],
      items: [],
      complaintStatusList: [],
      isBusy: false,
      selectAllCb: false,
      checkAll: false,
      rows: 0,
      filter: {
        callBack: [],
        complaintStatus: [],
        startDate: "",
        endDate: "",
        status: null,
        search: "",
        page: 1,
        pageSize: 10,
      },
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      totalRowMessage: "",
    };
  },
  created: async function () {
    this.$isLoading = true;
    await this.getData();
    await this.getComplaintStatus();
    this.$isLoading = false;
  },
  methods: {
    getData: async function () {
      this.isBusy = true;
      let resData = await this.$callApi(
        "put",
        `${this.$baseUrl}/api/feedback/list`,
        null,
        this.$headers,
        this.filter
      );
      if (resData.result == 1) {
        this.items = resData.detail.data;
        this.rows = resData.detail.totalData;
        if (resData.detail.totalData > 0) {
          let numRecordFirst = this.filter.page * this.filter.pageSize;
          numRecordFirst = numRecordFirst - this.filter.pageSize + 1;
          let numRecordLast =
            this.filter.page * this.filter.pageSize > resData.detail.totalData
              ? resData.detail.totalData
              : this.filter.page * this.filter.pageSize;
          this.totalRowMessage =
            "Showing " +
            numRecordFirst +
            " - " +
            numRecordLast +
            " of " +
            resData.detail.totalData +
            " entries";
        }

        this.isBusy = false;
      }
    },
    getComplaintStatus: async function () {
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/feedback/status`,
        null,
        this.$headers,
        null
      );
      if (resData.result == 1) {
        this.complaintStatusList = resData.detail;
      }
    },
    pagination(Page) {
      this.filter.page = Page;
      this.getData();
    },
    hanndleChangePerpage(value) {
      this.filter.page = 1;
      this.filter.pageSize = value;
      this.getData();
    },
    handleSearch(e) {
      if (e.keyCode === 13) {
        this.filter.page = 1;
        this.getData();
      }
    },
    btnSearch() {
      this.filter.page = 1;
      this.getData();
    },
    getDataByStatus() {
      this.$refs.filterSidebar.hide();
      this.getData();
    },
    onClearFilter() {
      this.filter.page = 1;
      this.filter.startDate = "";
      this.filter.endDate = "";
      this.filter.complaintStatus = [];
      this.$refs.filterSidebar.hide(true);
      this.getData();
    },
    checkAllSelect() {
      if (this.selectAllCb) {
        this.filter.complaintStatus = [];
      } else {
        var idList = this.complaintStatusList.map((x) => x.id);
        this.filter.complaintStatus = idList;
      }
    },
    exportData: async function (type) {
      this.$refs.loadingModal.show();
      this.filter.fileType = type;

      axios({
        url: `${this.$baseUrl}/api/feedback/export`,
        method: "post",
        headers: this.$headers,
        responseType: "blob",
        data: this.filter,
      })
        .then((response) => {
          this.$refs.loadingModal.hide();
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          var dateExcel = moment().format("DDMMYYYYhhmmss");

          var typeName = "";
          if (type == 1) typeName = ".xlsx";
          else typeName = ".csv";

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            `Feedback List-` + dateExcel + typeName
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error) => {
          if (error.response.status === 500) {
          }
        });
    },
  },
};
</script>       

<style scoped>
.doc-status {
  font-size: 16px;
  font-weight: bold;
}

.text-orange {
  color:#FF8C00;
}
</style>